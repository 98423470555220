@import '~vuetify/src/styles/styles.sass';



















































































































































































































































































































































































































































//@import '@design';
.v-expansion-panel-content__wrap {
  padding: 0;
}
